
import React, { useEffect, useState } from "react";
import { useDeleteJobMutation, useGetCustomerJobsQuery } from "../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import CommonTable from "../CommonTable/CommonTable";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CiSquarePlus } from "react-icons/ci";
import JDHeader from '../JobDetailHeader/JDHeader'
import JobDetail from "../JobDetails/JobDetail"
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaRegEdit,FaTrashAlt } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";

import { RiDeleteBin5Line } from "react-icons/ri";
import usePrivilege from "../Privileges/Privileges";


const JobOpening = () => {
  const [jobData, setJobData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth);
  const [params]=useSearchParams()
  // Fetch jobs from API
  const privilege = usePrivilege("Job Create");
  const { data, isSuccess, refetch } = useGetCustomerJobsQuery({
    domain_name: isAuth?.user?.domain_name,
    role_id: isAuth?.user?.role_id,
    user_id: isAuth?.user?.user_id,
  });

  useEffect(() => {
    refetch(); 
  }, [refetch]);

  useEffect(() => {
    if (isSuccess) {
      console.log("jobssdata",data?.message[0])
      setJobData(data?.message || []);
    }
  }, [isSuccess, data]);
  
     const [selectedColumns, setSelectedColumns] = useState([
        "id", "job_Title", "job_dept", "job_city", "job_status", "created_at"
      ]);
  const columns = [
    { key: "job_id", label: "Job ID" },
    { key: "job_title", label: "Job Title" },
    { key: "job_dept", label: "Department" },
    { key: "job_city", label: "Location" },
    { key: "job_status", label: "Status" },
    { key: "created_at", label: "Posted Date" },
   

  ];


  // const handleRowAction = (job) => {
  //   navigate(`?type=job_openings&id=${job.job_id}`);
  // };
 const [deleteJob]=useDeleteJobMutation();
 const handleDelete = async (e,job_id) => {
    e.preventDefault()
    const obj = {
      id:job_id
    }
    try{
      const res = await deleteJob({
        domain_name:isAuth?.user?.domain_name,
        data:obj
      })
      setShowConfirm(false)
      if(res?.data?.status){
        refetch()
      }
      console.log("res",res)
    }catch(err){

    }
}
  const handleRowAction = (job) => (
    
   
    <>
   
      {/* <button 
        onClick={() => navigate(`?type=job_openings&id=${job.job_id}`)}
        className="commonTable-dropdown-item"
      >
        <IoDocumentTextOutline /> View Job Detail
      </button> */}
      <button 
        onClick={()=>navigate(`EditJob/${job.job_id}`)}
        className="commonTable-dropdown-item"
      >
        <FaRegEdit /> Edit Job
      </button>
      <button 
      className="commonTable-dropdown-item"
      onClick={() => setShowConfirm(true)}>
       <MdOutlineDeleteOutline/>Delete Job
      </button>

      {showConfirm && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>Are you sure you want to delete this job?</p>
            <div className="popup-actions">
              <button  onClick={(e)=>handleDelete(e,job?.job_id)}>
                proceed
              </button>
              <button onClick={() => setShowConfirm(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {/* <button 
        onClick={() => console.log("Delete job:", job.job_id)}
        className="commonTable-dropdown-item danger"
      >
        <RiDeleteBin5Line/> Delete Job
      </button> */}
    </>
  );

  const sidebarLabels = [
    "All Jobs",
    "Active Jobs",
    "Inactive Jobs",
    "Recent Jobs",
    "My Jobs",
  ];

  const [selectedFilter, setSelectedFilter] = useState("All Jobs");
  const [sortOrder, setSortOrder] = useState("Newest First"); // Default sorting
 
  
  // Function to filter jobs based on the selected sidebar label
  const getFilteredAndSortedJobs = () => {
    let filteredJobs = [...jobData]; // Copy jobData to avoid mutating state
    console.log("jobdata==>", filteredJobs);
    
  
    // Apply status-based filtering
    if (selectedFilter === "Active Jobs") {
      filteredJobs = jobData.filter(job => job.job_status === "Active");
    } else if (selectedFilter === "Inactive Jobs") {
      filteredJobs = jobData.filter(job => job.job_status === "InActive");
    } else if (selectedFilter === "Recent Jobs") {
      filteredJobs = jobData.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (selectedFilter === "My Jobs") {
      filteredJobs = jobData.filter(job => job.created_by === isAuth?.user?.user_id);
    }
  
    // Apply sorting based on selected order
    const getParsedDate = (dateString) => {
      if (!dateString) return new Date(0);
      return new Date(dateString.replace("at", ""));
    };
    
    if (sortOrder === "Newest First") {
      filteredJobs.sort((a, b) => getParsedDate(b.created_at) - getParsedDate(a.created_at));
    } else if (sortOrder === "Oldest First") {
      filteredJobs.sort((a, b) => getParsedDate(a.created_at) - getParsedDate(b.created_at));
    }
  
    return filteredJobs;
  };

  useEffect(()=>{
    if(params.get("filter")==="active-jobs"){
        setSelectedFilter("Active Jobs")
        getFilteredAndSortedJobs()
    }
},[])

  return (
    <>
    {params.get("type")&&params.get("id")?(
      <>
      <JDHeader/>
      <JobDetail/>
      </>
    ):
    (
      <>
    <CommonTable
    module_name={"job_openings"}
      detailNavigate={"/ats/dashboard/?type=job_openings"}
      headerButton="Add Job"
      headerIcon={<CiSquarePlus />}
      headerNavigateTo="/ats/dashboard/?type=createJob"
      columns={columns}
      // data={jobData}
      meta_title={"Jobs"}
      data={getFilteredAndSortedJobs()} // Pass the filtered data
      onRowAction={handleRowAction}
      title={selectedFilter}
      sidebarLabels={sidebarLabels} 
      rowIdentifier="job_id"
      privilege={"Job Create"}
      onFilterChange={setSelectedFilter} // Pass filter change handler
      onSortChange={setSortOrder} // ✅ Handle sorting change
      selectedColumns={selectedColumns} // ✅ Pass selected columns
      onColumnChange={setSelectedColumns} // ✅ Handle column filtering
    />
    </>
    )}

    
    </>
  );
};

export default JobOpening;
