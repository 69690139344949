import React, { useEffect, useState } from "react";
import {
  useGetSMTPConfigQuery,
  useSendTestEmailMutation,
  useUpdateSMTPMutation,
} from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import "./SMTPsetting.css";
import { showToast } from "../../../ToastContainer/ToastProvider";

const SMTPSettings = () => {
  const isAuth = useSelector((state) => state.auth);

  const [testemail, settestemail] = useState({ to_email: "" });

  const [smtp, setsmtp] = useState([
    {
      label: "SMTP Server",
      type: "text",
      placeholder: "e.g., smtp.gmail.com",
      value: "",
      id: "smtp_host",
    },
    {
      label: "Port",
      type: "number",
      placeholder: "e.g., 465 or 587",
      value: "",
      id: "smtp_port",
    },
    {
      label: "Secure Connection (TLS & SSL)",
      type: "checkbox",
      value: false,
      id: "secure_connection",
    },
    {
      label: "Username (Email Address)",
      type: "email",
      placeholder: "e.g., your-email@example.com",
      value: "no-reply@logiglo.com",
      id: "username",
    },
    {
      label: "Password",
      type: "password",
      note: "Stored securely or use an app-specific password.",
      value: "Admin@123!",
      id: "password",
    },
    {
      label: "Sender Name (optional)",
      type: "text",
      placeholder: "e.g., John Doe",
      value: "",
      id: "from_name",
    },
    {
      label: "Sender Email (optional)",
      type: "email",
      placeholder: "e.g., contact@logiglo.com",
      value: "",
      id: "from_email",
    },
    {
      label: "Ignore TLS Certificate Errors",
      type: "checkbox",
      value: false,
      id: "tls_ignore_errors",
    },
  ]);

  const { data, isSuccess, refetch } = useGetSMTPConfigQuery({
    domain_name: isAuth?.user?.domain_name,
    userid: isAuth?.user?.user_id,
  });

  useEffect(() => {
    refetch();
    if (data?.status) {
      setsmtp((prev) =>
        prev.map((item) => ({
          ...item,
          value: data?.message?.[0]?.[item?.id],
        }))
      );
    }
  }, [isSuccess, data]);

  const [update] = useUpdateSMTPMutation();

  const updateSMTP = async () => {
    const obj = {};
    smtp.forEach((item) => {
      if (item?.type === "checkbox") {
        obj[item.id] = item.value ? 1 : 0;
      } else {
        obj[item.id] = item.value;
      }
    });

    try {
      const res = await update({
        domain_name: isAuth?.user?.domain_name,
        userid: isAuth?.user?.user_id,
        data: obj,
      });
      if (res) showToast(res?.data?.status, res?.data?.message);
    } catch (err) {
      showToast(err?.data?.status, err?.data?.message);
    }
  };

  const [sendTest] = useSendTestEmailMutation();

  const handleTest = async () => {
    try {
      const res = await sendTest({
        domain_name: isAuth?.user?.domain_name,
        userid: isAuth?.user?.user_id,
        email_data: testemail,
      });
      console.log("res",res)
      if (res) showToast(res?.data?.status, res?.data?.message);
      settestemail({ to_email: "" });
    } catch (err) {
      showToast(err?.data?.status, err?.data?.message);
      settestemail({ to_email: "" });
    }
  };

  const ParentSmtpCard = ({ smtpCardHeader, children }) => {
    return (
      <div className="smtp-card-wrapper">
        <h3 className="smtp-card-header">{smtpCardHeader}</h3>
        <div className="smtp-card-body">{children}</div>
      </div>
    );
  };

  const renderField = (item) => {
    const isCheckbox = item?.type === "checkbox";
    const checkedValue =
      isCheckbox && (item?.value === "1" || item?.value === 1 || item?.value === true);

    return (
      <div className="setting-smtp-control-field" key={item.id}>
        {isCheckbox ? (
          <div className="setting-smtp-control-checkbox-wrapper">
            <input
              type="checkbox"
              checked={checkedValue}
              onChange={(e) => {
                setsmtp((prev) =>
                  prev.map((field) =>
                    field.id === item.id
                      ? { ...field, value: e.target.checked }
                      : field
                  )
                );
              }}
            />
            <label className="setting-smtp-control-checkbox-label">
              {item.label}
            </label>
          </div>
        ) : (
          <>
            <label className="setting-smtp-control-label">{item.label}</label>
            <input
              className="setting-smtp-control-input"
              value={item.value}
              type={item?.type}
              placeholder={item?.placeholder}
              onChange={(e) => {
                setsmtp((prev) =>
                  prev.map((field) =>
                    field.id === item.id
                      ? { ...field, value: e.target.value }
                      : field
                  )
                );
              }}
            />
          </>
        )}
      </div>
    );
  };

  const firstThree = smtp.slice(0, 3);
  const remaining = smtp.slice(3);

  return (
    <div className="setting-smtp-control-wrapper">
      <ParentSmtpCard smtpCardHeader="SMTP Server Details">
        {firstThree.map(renderField)}
      </ParentSmtpCard>

      <ParentSmtpCard smtpCardHeader="Authentication & Sender Info">
        {remaining.map(renderField)}
      </ParentSmtpCard>

      <button className="setting-smtp-control-update-btn" onClick={updateSMTP}>
        Update
      </button>

      {data?.message?.[0]?.username && (
        <div className="setting-smtp-control-test-mail">
          <p>
            From Mail:{" "}
            {data.message?.[0]?.from_email
              ? data.message?.[0]?.from_email
              : data.message?.[0]?.username}
          </p>

          <input
            className="setting-smtp-control-input"
            type="email"
            placeholder="Enter test email"
            value={testemail.to_email}
            onChange={(e) =>
              settestemail({ ...testemail, to_email: e.target.value })
            }
          />
          <button
            className="setting-smtp-control-send-btn"
            onClick={handleTest}
          >
            Send Test Email
          </button>
        </div>
      )}
    </div>
  );
};

export default SMTPSettings;
