import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./CreateUser.css";
import {
  useCreateUserMutation,
  useGetHierachyQuery,
  useGetRoleQuery,
  useGetUsersQuery,
} from "../../../../Redux/API/atsSlice";
import { useGetCountryListQuery } from "../../../../Redux/API/homeSlice";
import { FaSearch, FaUserEdit } from "react-icons/fa";
import { showToast } from "../../../ToastContainer/ToastProvider";

const CreateUser = () => {
  const [iscreateUser, setisCreateUser] = useState(false);
  const [searchSettings, setSearchSettings] = useState("");
  const [SettingShowForm, setSettingShowForm] = useState(false);
  const isAuth = useSelector((state) => state.auth);
  const [form, setForm] = useState({
    domain_name: "",
    firstname: "",
    lastname: "",
    email_id: "",
    password: "",
    created_by: "",
    role_name: "",
    role_id: "",
    reportingManager: [],
    dottedLineManager: [],
    phonecode: "",
    mobile_number: "",
  });

  const { data: hierachy, isSuccess: hierachySuccess } = useGetHierachyQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  const [createUser] = useCreateUserMutation();
  const handleSubmit = async () => {
    try {
      const response = await createUser({
        domain_name: isAuth?.user?.domain_name,
        firstname: form?.firstname,
        lastname: form?.lastname,
        email_id: form.email_id,
        password: form.password,
        created_by: isAuth?.user?.user_id,
        role_name: form?.role_name,
        role_id: form?.role_id,
        phonecode: form.phonecode,
        mobilenumber: form?.mobile_number,
        reporting_manager:
          form.reportingManager?.length > 0
            ? form.reportingManager[0]?.h_id
            : "",
        dottedlinemanager:
          form.dottedLineManager?.length > 0
            ? form?.dottedLineManager[0]?.value
            : "",
      });
      console.log(response);
      if (response) showToast(response.data.status, response.data.status);
      setForm({
        domain_name: "",
        firstname: "",
        lastname: "",
        email_id: "",
        password: "",
        created_by: "",
        role_name: "",
        role_id: "",
        reportingManager: [],
        dottedLineManager: [],
        phonecode: "",
        mobile_number: "",
      });
      // console.log(response);
    } catch (err) {}
  };

  const {
    data: CountryList,
    isSuccess: CountrySuccess,
    refetch: CountryRefetch,
  } = useGetCountryListQuery();
  const [searchText, setSearchText] = useState("");
  const [searchText2, setsearchText2] = useState("");
  const [SearchedManager, setSearchManager] = useState([]);
  const [SearchDottedLine, setSearchDottedLine] = useState([]);
  const {
    data: AllUser,
    isSuccess: AlluserSuccess,
    refetch: AllUserRefetch,
  } = useGetUsersQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  // console.log("AllUser==>", AllUser);

  useEffect(() => {
    if (searchText.length === 0) {
      setSearchManager([]);
    } else {
      setSearchManager(
        hierachy?.message?.filter((item, i) =>
          item?.label
            ?.toLowerCase()
            ?.replaceAll(" ", "")
            ?.includes(searchText?.toLowerCase().replaceAll(" ", ""))
        )
      );
    }
  }, [searchText]);

  useEffect(() => {
    if (searchText2.length === 0) {
      setSearchDottedLine([]);
    } else {
      setSearchDottedLine(
        AllUser?.message?.filter((item, i) =>
          item?.label
            ?.toLowerCase()
            ?.replaceAll(" ", "")
            ?.includes(searchText2?.toLowerCase().replaceAll(" ", ""))
        )
      );
    }
  }, [searchText2]);

  const [roleData, setroleData] = useState();
  const { data, refetch, isSuccess } = useGetRoleQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  useEffect(() => {
    if (data?.status) {
      setroleData(data?.message || []);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (roleData?.length > 0 && form.role_name) {
      const foundRole = roleData.find(
        (item) => item.role_name === form.role_name
      );
      if (foundRole?.role_id !== form.role_id) {
        // Prevent unnecessary state updates
        setForm((prev) => ({ ...prev, role_id: foundRole?.role_id || "" }));
      }
    }
  }, [roleData, form.role_name]);

  const filterUsers = (users, query) => {
    if (!query) return users;
    return users.filter(
      (user) =>
        user.label?.toLowerCase().includes(query.toLowerCase()) ||
        user.role_name?.toLowerCase().includes(query.toLowerCase())
    );
  };
  var input = document.getElementById('custominput');
  // useEffect(()=>{
  //   input?.addEventListener('keydown', ({key}) => {
  //     if (key === "Backspace" || key === "Delete") {
  //         setForm({...form,reportingManager:""})
  //     }
  // });
  // },[form])
  

 
  return (
    <>
      <h3>Create User</h3>
      <div className="setting-create-user-container">
        {/* search bar */}
        <div className="setting-create-user-search">
          <FaSearch className="setting-createuser-search-icon" />
          <input
            type="text"
            placeholder="Search..."
            className="Setting-createsearch"
            value={searchSettings}
            onChange={(e) => setSearchSettings(e.target.value)}
          />
        </div>
        
        {/* add user button */}
        <div className="setting-create-user-button">
          <button className="ftn" onClick={() => setSettingShowForm(true)}>
            Add User <FaUserEdit className="setting-create-user-icon" />
          </button>
          {SettingShowForm && (
            <div className="create-User-overlay">
              <div className={`Create-User-hidden-form Create-user-show-form`}>
                <div className="Create-Access-role-modal-header">
                  Add User
                  <button onClick={() => setSettingShowForm(false)}>✖</button>
                </div>
                <div className="setting-create-user-modal-body">
                  {/* <!-- First Name & Last Name --> */}
                  <div className="setting-create-user-row">
                    <input
                      type="text"
                      className="setting-create-user-input"
                      placeholder="Enter the FirstName"
                      value={form?.firstname}
                      onChange={(e) =>
                        setForm({ ...form, firstname: e.target.value })
                      }
                    />
                    <input
                      type="text"
                      className="setting-create-user-input"
                      placeholder="Enter the LastName"
                      value={form.lastname}
                      onChange={(e) =>
                        setForm({ ...form, lastname: e.target.value })
                      }
                    />
                  </div>
                  {/* 
    <!-- Role & Role ID --> */}
                  <div className="setting-create-user-row">
                    <select
                      className="setting-create-user-input"
                      value={form?.role_name}
                      onChange={(e) =>
                        setForm({ ...form, role_name: e.target.value })
                      }
                    >
                      {roleData &&
                        roleData.map((item, i) => (
                          <option value={item?.role_name} key={i}>
                            {item?.role_name}
                          </option>
                        ))}
                    </select>
                    <input
                      type="text"
                      className="setting-create-user-input"
                      placeholder="Enter the Role ID"
                      value={form.role_id}
                      readOnly
                    />
                  </div>

                  {/* <!-- Email & Password --> */}
                  <div className="setting-create-user-row">
                    <input
                      type="email"
                      className="setting-create-user-input"
                      placeholder="Enter the Email ID"
                      value={form.email_id}
                      onChange={(e) =>
                        setForm({ ...form, email_id: e.target.value })
                      }
                    />
                    <input
                      type="text"
                      className="setting-create-user-input"
                      placeholder="Enter the Password"
                      value={form.password}
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                    />
                  </div>

                  {/* <!-- Mobile Number --> */}
                  <div className="setting-create-user-row">
                    <div className="setting-create-user-mobile">
                      <select
                        className="setting-create-user-code"
                        value={form.phonecode}
                        onChange={(e) =>
                          setForm({ ...form, phonecode: e.target.value })
                        }
                      >
                        {CountryList?.status &&
                          CountryList?.message.map((item, i) => (
                            <option value={item?.phonecode} key={i}>
                              {item?.name} {item?.phonecode}
                            </option>
                          ))}
                      </select>
                      <input
                        type="text"
                        className="setting-create-user-input"
                        value={form.mobile_number}
                        onChange={(e) =>
                          setForm({ ...form, mobile_number: e.target.value })
                        }
                        placeholder="Enter the Contact Number"
                      />
                    </div>
                  </div>

                  {/* <!-- Reporting Manager --> */}
                  <div className="setting-create-user-row">
                    <input
                      type="text"
                      className="setting-create-user-input"
                      placeholder="Search for Reporting Manager"
                      value={
                        form.reportingManager?.length > 0
                          ? form.reportingManager[0]?.label
                          : searchText
                      }
                      id="custominput"
                      onChange={(e) => {
                        if (form.reportingManager.length === 0)
                          setSearchText(e.target.value);
                      }}
                    />
                  </div>

                  {form.reportingManager.length === 0 &&
                    SearchedManager.length > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          width: "95%",
                          maxHeight: "200px",
                          overflowY: "auto",
                          zIndex: 1000,
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          marginTop: "4px",
                        }}
                      >
                        {SearchedManager.map((item, i) => (
                          <div
                            className="setting-create-user-manager"
                            key={i}
                            onClick={() => {
                              if (form.reportingManager.length === 0) {
                                setForm({ ...form, reportingManager: [item] });
                                setSearchText(""); // Hide dropdown
                              }
                            }}
                          >
                            <p>{item?.label}</p>
                            <p>{item?.value}</p>
                          </div>
                        ))}
                      </div>
                    )}

                  {/* <!-- Dotted Line Manager --> */}
                  <div className="setting-create-user-row">
                    <input
                      type="text"
                      className="setting-create-user-input"
                      placeholder="Dotted Line Manager (Optional)"
                      value={
                        form.dottedLineManager?.length > 0
                          ? form.dottedLineManager[0]?.label
                          : searchText2
                      }
                      onChange={(e) => {
                        if (form.dottedLineManager.length === 0)
                          setsearchText2(e.target.value);
                      }}
                    />
                  </div>

                  {/* ✅ Show dropdown only when no dotted line manager is selected */}
                  {form.dottedLineManager.length === 0 && SearchDottedLine && (
                    <div
                      style={{
                        position: "absolute",
                        width: "95%",
                        backgroundColor: "white",
                        maxHeight: "200px",
                        overflowY: "auto",
                        zIndex: 10,
                        boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                      }}
                    >
                      {SearchDottedLine.map((item, i) => (
                        <div
                          key={i}
                          className="setting-create-user-manager"
                          onClick={() => {
                            setForm({ ...form, dottedLineManager: [item] });
                            setsearchText2(""); // Clear input after selection
                          }}
                          style={{
                            padding: "10px",
                            borderBottom: "1px solid #eee",
                            cursor: "pointer",
                          }}
                        >
                          <p style={{ margin: 0, fontWeight: "bold" }}>
                            {item?.label}
                          </p>
                          <p style={{ margin: 0, color: "#777" }}>
                            {item?.value}
                          </p>
                        </div>
                      ))}

                      {/* ✅ Cancel inside dropdown */}
                      <div style={{ textAlign: "right", padding: "5px 10px" }}>
                        <button
                          className="setting-create-user-cancel-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            setForm({ ...form, dottedLineManager: [] });
                            setsearchText2("");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="Create-User-Access-role-modal-footer">
                  <button
                    className="Create-User-Access-role-cancel-button"
                    onClick={() => setSettingShowForm(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="Create-User-Access-role-save-button"
                    onClick={handleSubmit}
                  >
                    Add User
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>




{/* existing users table */}
      <div class="settings-createuser-table-container">
        <div class="settings-createuser-table-wrapper">
          <table class="settings-createuser-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Contact number</th>
                <th>Email</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {filterUsers(AllUser?.message || [], searchSettings).map(
                (item, i) => (
                  <tr key={i}>
                    <td>{item.label || "NA"}</td>
                    <td>{item.role_name || "NA"}</td>
                    <td>{item.mobile_number || "NA"}</td>
                    <td>{item.email_id || "NA"}</td>
                    <td>{item.created_by || "NA"}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
