import React, { useEffect, useState } from "react";
import FontPicker from "font-picker-react";

const FontChange = ({ state, onchange, type, id }) => {
  const [activeFontFamily, setActiveFontFamily] = useState("Open Sans");
  const [isFontListLoaded, setIsFontListLoaded] = useState(false);

  useEffect(() => {
    // Wait for font list to load, then set default font if it's allowed
    if (isFontListLoaded && state[type]) {
      setActiveFontFamily(state[type]);
    }
  }, [isFontListLoaded, state, type]);

  return (
    <div>
      <FontPicker
        pickerId={id}
        apiKey="AIzaSyB9I4oEEL3jLUqXhdOOUGwr_UwjvTKAS74"
        activeFontFamily={activeFontFamily}
        onChange={(nextFont) => {
          setActiveFontFamily(nextFont.family);
          onchange((prevState) => ({
            ...prevState,
            [type]: nextFont.family,
          }));
        }}
        onLoad={() => {
          // Called once font list is loaded
          setIsFontListLoaded(true);
        }}
      />
      <p
        className={`apply-font-${id}`}
        style={{ fontFamily: activeFontFamily }}
      >
        The font will be applied to this text.
      </p>
    </div>
  );
};

export default FontChange;
