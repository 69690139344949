import React, { useEffect, useState } from 'react';
import './CandidateComments.css';
import { useCreateCommentsMutation, useGetcommentsQuery } from '../../../../Redux/API/atsSlice';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom'; 

const CandidateComments = () => {
  const [showInput, setShowInput] = useState(false);
  const [comment, setComment] = useState('');
  const[previousComments,setPreviousComments] =useState([])
  const isAuth = useSelector((state) => state.auth);
  const [params] = useSearchParams(); 
  const [addComments] = useCreateCommentsMutation();
const candidate_id = params.get('id');
  const {data,isSuccess,refetch} =useGetcommentsQuery({
    domain_name:isAuth?.user?.domain_name,
    candidate_id: candidate_id

  })

  console.log("candidateid ===",candidate_id)
  console.log("dattaa",data)
  useEffect(()=>{
    if(data?.status && data?.message){
       setPreviousComments(data?.message)
       if(data?.message.length===0){
        setShowInput(true)
       }
      }
   

  },[data,isSuccess])

  console.log("previous data",previousComments)

  const handleAddComment = () => {
    setShowInput(true);
  };

  const handleCancel = () => {
    setComment('');
    setShowInput(false);
  };


const handleSubmit = async () => {
    if (!comment.trim()) return;
    console.log("auth",isAuth)
    const data = { 
      candidate_id:candidate_id,
      body: comment,
      created_by: isAuth?.user?.user_id,
    };
  
    try {
      const response = await addComments({
        domain_name:isAuth?.user?.domain_name,
        data:data
      }).unwrap();
      console.log('Backend response:', response);
      console.log('Comment submitted:', data);
      setComment('');
      setShowInput(false);
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };
  
  return (
    <div className="candidate-comment-section">
      {previousComments.map((item)=>(
         <div className="candidate-comment" key={item.id}>
         <strong>{item.created_by} made a comment</strong>
         <span className="candidate-comment-date">{item.created_at}</span>
         <p className='candidate-comment-message'>{item.body}</p>
       </div>

      ))}

      {showInput ? (
        <div className="candidate-comment-box">
          <textarea
            rows="3"
            placeholder="Write your comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="candidate-comment-actions">
            <button onClick={handleCancel} className="candidate-cancel-btn">Cancel</button>
            <button onClick={handleSubmit} className="candidate-submit-btn">Comment</button>
          </div>
        </div>
      ) : (
        <button onClick={handleAddComment} className="candidate-add-comment-btn">Add Comments</button>
      )}
    </div>
  );
};

export default CandidateComments;

