
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useAssignJobtoACandidateMutation,
  useGetCustomerCandidatesQuery,
  useGetCustomerJobsQuery,
  useLazyGetAssignedJobsQuery,
  useGetAssignedJobsQuery,
  useDeleteCandidatesMutation
} from "../../Redux/API/atsSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import CandidateDetail from "./CandidateDetail";
import Modal from "react-modal";
import "../Modal/Modal.css";
import CommonTable from "../CommonTable/CommonTable";
import { CiSquarePlus } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";

import { IoPersonAddOutline } from "react-icons/io5";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaRegEdit,FaTrashAlt } from "react-icons/fa";


import "./Candidates.css"
import usePrivilege from "../Privileges/Privileges";
import { ref } from "yup";

const Candidates = () => {
  const isAuth = useSelector((state) => state.auth);
  const [candidates, setCandidates] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [selectedCandidate, setSelectedCandidate] = useState(null); 
  const [selectedJobs, setSelectedJobs] = useState([]); 
  const [JobsData, setJobs] = useState([]); 
  const [isOpen, setIsOpen] = useState(false); 
  const [isAssignJobModalOpen, setIsAssignJobModalOpen] = useState(false); 
  const [currentCandidate, setCurrentCandidate] = useState(null); 


  // Fetch candidates
  const { data: candidatesData, refetch:CandidateRefetch } = useGetCustomerCandidatesQuery({
    domain_name: isAuth?.user?.domain_name,
    role_id: isAuth?.user?.role_id,
    user_id: isAuth?.user?.user_id,
  });

  console.log("candidate dattaaa=",candidatesData)
  // Fetch jobs
  const { data: jobsData, isSuccess: jobsSuccess } = useGetCustomerJobsQuery({
    domain_name: isAuth?.user?.domain_name,
    role_id: isAuth?.user?.role_id,
    user_id: isAuth?.user?.user_id,
  });

  // Fetch assigned jobs
  const [fetchAssignedJobs, { isSuccess: assignedJobsSuccess }] = useLazyGetAssignedJobsQuery();

  // Update candidates state
  useEffect(() => {
    if (candidatesData?.message) {
      setCandidates(candidatesData.message);
    }
  }, [candidatesData]);

  // Update jobs state
  useEffect(() => {
    if (jobsData?.status) {
      setJobs(jobsData.message || []);
    }
  }, [jobsSuccess, jobsData]);

  // Fetch assigned jobs when a candidate is selected
 
  const {
    data:AssignedJobs,refetch, isSuccess, isFetching, isUninitialized
  } = useGetAssignedJobsQuery({
    domain_name: isAuth?.user.domain_name,
        candidate_id: currentCandidate?.candidate_id,
  })

  useEffect(()=>{
    refetch()
  },[currentCandidate,isOpen])


  useEffect(() => {
   // refetch()
    if(isSuccess && AssignedJobs?.status){
      setSelectedJobs(AssignedJobs?.message)
    }
    // setSelectedJobs(AssignedJobs?.message)
    //if (currentCandidate) {
      // fetchAssignedJobs({
      //   domain_name: isAuth?.user.domain_name,
      //   candidate_id: currentCandidate?.candidate_id,
      // }).then((response) => {
      //   console.log("response",response)
      //   if (response?.data?.status && response?.data?.message?.length > 0) {
      //     setSelectedJobs(response.data.message);
      //   }
      // });
  //  }
  }, [isSuccess,currentCandidate,isOpen,AssignedJobs]);

   const isCandidateUpdate = usePrivilege('Candidate Create')
  // Open modal for job assignment
  const openModal = () => {
    if (selectedCandidate) {
      refetch()
      setIsOpen(true);
    } else {
      alert("Please select a candidate first.");
    }
  };


  // Handle job assignment
  const [assignJobs] = useAssignJobtoACandidateMutation();
  const handleAssignJobs = async (e) => {
    e.preventDefault();
    console.log("Candidate",selectedCandidate)
    try {
      const response = await assignJobs({
        domain_name: isAuth?.user.domain_name,
        data: [
          {
            candidate_id:currentCandidate?.candidate_id,
            // candidate_id: candidates?.candidate_id,
            job_id: selectedJobs,
          },
        ],
      });
  
      setIsOpen(false); 
    } catch (err) {
      console.error("Error assigning jobs:", err);
    }
  };

  // Handle job selection
  const handleJobSelection = (jobId, isChecked) => {
    setSelectedJobs((prevSelected) =>
      isChecked
        ? [...prevSelected, jobId] // Add job if checked
        : prevSelected.filter((id) => id !== jobId) // Remove job if unchecked
    );
  };

  // Columns for the CommonTable
  const columns = [
    { key: "candidate_id", label: "Candidate ID" },
    { key: "first_name", label: "First Name" },
    { key: "last_name", label: "Last Name" },
    { key:'contact_number', label:"Mobile"},
    { key:'email_id',label:"Email"},
    { key:'experience',label:'Experience'}
    
  ];

  // Sidebar labels for the CommonTable
  const sidebarLabels = [
    "All Candidates",
    "Active Candidates",
    "Inactive Candidates",
    "Recent Candidates",
    "My Candidates",
  ];
  const [selectedFilter, setSelectedFilter] = useState("All Candidates");
  const [sortOrder, setSortOrder] = useState("Newest First");
  const [selectedColumns, setSelectedColumns] = useState([
    "candidate_id", "first_name", "last_name", "status", "created_at","contact_number","email_id","experience"
  ]);
  
  const getFilteredAndSortedCandidates = () => {
    let filteredCandidates = [...candidates];

    if (selectedFilter === "Active Candidates") {
      filteredCandidates = candidates.filter(candidate => candidate.status === "Active");
    } else if (selectedFilter === "Inactive Candidates") {
      filteredCandidates = candidates.filter(candidate => candidate.status === "Inactive");
    } else if (selectedFilter === "Recent Candidates") {
      filteredCandidates = candidates.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    if (sortOrder === "Newest First") {
      filteredCandidates.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (sortOrder === "Oldest First") {
      filteredCandidates.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }

    return filteredCandidates;
  };
const [deleteCandidate]=useDeleteCandidatesMutation();
const handleDelete = async (e,candidate_id) => {
  e.preventDefault()
  const obj = {
    id:candidate_id
  }
  try{
    const res = await deleteCandidate({
      domain_name:isAuth?.user?.domain_name,
      data:obj
    })
    console.log("res",res)
    setShowConfirm(false)
    if(res?.data?.status){
     CandidateRefetch()
    }
   
  }catch(err){

  }
}
  // Handle row actions (dropdown actions)
  const handleRowAction = (candidate,index) => {

    return (
      <>
        {/* <button
          onClick={() => navigate(`?type=candidates&id=${candidate.candidate_id}&idx=${index}`)}
          className="commonTable-dropdown-item"
        >
          <IoDocumentTextOutline /> View Candidate Detail
        </button> */}
        <button
          onClick={() => {
            refetch()
            setCurrentCandidate(candidate);
            setIsAssignJobModalOpen(true); 
            setSelectedJobs(AssignedJobs?.message)
          }}
          className="commonTable-dropdown-item"
        >
          <IoPersonAddOutline /> Assign a Job
        </button>
        <button
          onClick={() => navigate(`EditCandidate/${candidate.candidate_id}`)}
          className="commonTable-dropdown-item"
        >
          <FaRegEdit /> Edit Candidate
        </button>

        <button 
         className="commonTable-dropdown-item"
        onClick={() => setShowConfirm(true)}>
          
       <MdOutlineDeleteOutline/> Delete Candidate
      </button>

      {showConfirm && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>Are you sure you want to delete this candidate?</p>
            <div className="popup-actions">
              <button  onClick={(e)=>handleDelete(e,candidate?.candidate_id)}>
                proceed
              </button>
              <button onClick={() => setShowConfirm(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
        {/* <button
          onClick={() => console.log("Delete candidate:", candidate.candidate_id)}
          className="commonTable-dropdown-item danger"
        >
          <RiDeleteBin5Line/> Delete Candidate
        </button> */}
      </>
    );
  };

  return (
    <div>
      {params.get("type") && params.get("id") ? (
        <CandidateDetail />
      ) : (
        <>
          {selectedCandidate && (
            <>
              <button onClick={openModal}>Assign to Job</button>
            <button disabled={!isCandidateUpdate.includes(isAuth?.user?.user_id)} onClick={(e)=>{
              navigate(`/ats/dashboard/EditCandidate/${selectedCandidate?.candidate_id}`)
            }}>Edit</button>
            </>
          )}
        
       
          <CommonTable
          module_name={"candidates"}
          meta_title={"Candidates"}
          detailNavigate={"/ats/dashboard/?type=candidates"}
            headerButton="Create Candidate"
            headerIcon={<CiSquarePlus />}
            headerNavigateTo="/ats/dashboard/?type=createCandidates"
            columns={columns}
            // data={candidates}
            data={getFilteredAndSortedCandidates()}
            onRowAction={handleRowAction}
            title={selectedFilter}
            sidebarLabels={sidebarLabels}
            rowIdentifier="candidate_id"
            privilege={"Candidate Create"}
            onFilterChange={setSelectedFilter}
            onSortChange={setSortOrder}
            selectedColumns={selectedColumns} // ✅ Pass selected columns
            onColumnChange={setSelectedColumns}
          />
        </>
      )}

      {/* Modal for Assigning Jobs (from the table row action) */}
      {/* <Modal
        isOpen={isAssignJobModalOpen}
        onRequestClose={() => setIsAssignJobModalOpen(false)}
        contentLabel="Assign Jobs"
        className={{
          base: "custom-modal-base",
          afterOpen: "custom-modal-base_after-open",
          beforeClose: "custom-modal-base_before-close",
        }}
        overlayClassName={{
          base: "custom-overlay-base",
          afterOpen: "custom-overlay-base_after-open",
          beforeClose: "custom-overlay-base_before-close",
        }}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={2000}
      >
        <div>
          <button onClick={() => setIsAssignJobModalOpen(false)}>Close</button>
          <h3>Assign Jobs for {currentCandidate?.first_name}</h3>

          {JobsData.map((job) => (
            <div key={job.job_id} style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={selectedJobs.includes(job.job_id)}
                onChange={(e) => handleJobSelection(job.job_id, e.target.checked)}
              />
              <p>{job.job_title}</p>
            </div>
          ))}

          <button onClick={handleAssignJobs}>Save Changes</button>
        </div>
      </Modal> */}

<Modal
  isOpen={isAssignJobModalOpen}
  onRequestClose={() => setIsAssignJobModalOpen(false)}
  contentLabel="Assign Jobs"
  className="custom-modal-base"
  overlayClassName="custom-overlay-base"
  shouldCloseOnOverlayClick={true}
  closeTimeoutMS={200}
>
  <div className="modal-header">
    <h3>Assign Jobs for – {currentCandidate?.first_name} {currentCandidate?.candidate_id}</h3>
    <button className="modal-close" onClick={() => setIsAssignJobModalOpen(false)}>✖</button>
  </div>

  <div className="modal-content">
    {JobsData.map((job) => (
      <div key={job.job_id} className="job-selection">
        <input
          type="checkbox"
          checked={selectedJobs.includes(job.job_id)}
          onChange={(e) => handleJobSelection(job.job_id, e.target.checked)}
        />
        <p>{job.job_title}</p>
      </div>
    ))}
  </div>

  <div className="modal-footer">
    <button className="cancel-btn" onClick={() => setIsAssignJobModalOpen(false)}>Cancel</button>
    <button className="save-btn" onClick={handleAssignJobs} disabled={selectedJobs?.length === 0}>
      Save Changes
    </button>
  </div>
</Modal>


      {/* Modal for Assigning Jobs (from the main button) */}
      {/* <Modal
        isOpen={isOpen}
        contentLabel="Assign Jobs"
        className={{
          base: "custom-modal-base",
          afterOpen: "custom-modal-base_after-open",
          beforeClose: "custom-modal-base_before-close",
        }}
        overlayClassName={{
          base: "custom-overlay-base",
          afterOpen: "custom-overlay-base_after-open",
          beforeClose: "custom-overlay-base_before-close",
        }}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={2000}
      >
        <div>
          <button onClick={() => setIsOpen(false)}>Close</button>

          {selectedCandidate && <h3>Assign Jobs for {selectedCandidate?.first_name}</h3>}

          {JobsData.map((job) => (
            <div key={job.job_id} style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={selectedJobs.includes(job.job_id)}
                onChange={(e) => {
                  const { checked, value } = e.target;
                  setSelectedJobs((prevSelected) =>
                    checked
                      ? [...new Set([...prevSelected, value])] 
                      : prevSelected.filter((id) => id !== value) 
                  );
                }}
              />
              <p>{job.job_title}</p>
            </div>
          ))}
          <button onClick={handleAssignJobs}>Save Changes</button>
        </div>
      </Modal> */}
    </div>
  );
};

export default Candidates;