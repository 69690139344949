// import DocViewer, { PDFRenderer, PNGRenderer, DocViewerRenderers,JPGRenderer }  from "react-doc-viewer";
// import pdf from "./prabhu_m_s.pdf"

// import DocViewer, { DocViewerRenderers , PDFRenderer} from "@cyntler/react-doc-viewer";
// import "@cyntler/react-doc-viewer/dist/index.css";

import { useEffect, useState } from "react";
import * as XLSX from "xlsx"; // For Excel
import mammoth from "mammoth"; 


const Preview = ({filetobePreview}) =>{
    const [preview, setPreview] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileContent, setFileContent] = useState("");

 
  const handleFileChange = (event) => {
   //const file = event.target.files[0];
   const file = filetobePreview
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
      setFileType(file.type);
      setFileName(file.name);
      
      if (file.name.match(/\.(doc|docx)$/)) {
        // Word File Preview
        const reader = new FileReader();
        reader.onload = (e) => {
          mammoth
            .extractRawText({ arrayBuffer: e.target.result })
            .then((result) => setFileContent(result.value))
            .catch(() => setFileContent("Unable to preview Word file."));
        };
        reader.readAsArrayBuffer(file);
      } else if (file.name.match(/\.(xls|xlsx)$/)) {
        // Excel File Preview
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
          setFileContent(JSON.stringify(sheet, null, 2)); // Display as JSON
        };
        reader.readAsArrayBuffer(file);
      } else {
        setFileContent(""); // Clear text content for non-text files
      }
    }
  };

  useEffect(()=>{
      handleFileChange()
  },[filetobePreview])
    return(
        <div style={{width:'100%',height:'100vh',maxHeight:'100vh',minHeight:'100vh'}}>
        {/* <input type="file" onChange={handleFileChange} /> */}
  
        {preview && (
          <div style={{height:'100vh'}}>
            {/* Image Preview */}
            {fileType.startsWith("image/") && <img src={preview} alt="Preview" width="100%"  height={'100%'} />}
  
            {/* PDF Preview */}
            {fileType === "application/pdf" && (
              <iframe src={`${preview}#toolbar=0&navpanes=0&view=fitH`}  width="95%" height="100%" title="PDF Preview"></iframe>
            )}
  
            {/* Video Preview */}
            {fileType.startsWith("video/") && (
              <video controls width="300">
                <source src={preview} type={fileType} />
                Your browser does not support the video tag.
              </video>
            )}
  
            {/* Word File Preview */}
            {fileName.match(/\.(doc|docx)$/) && (
              <div>
                <h4>Word Document Preview:</h4>
                <pre>{fileContent}</pre>
              </div>
            )}
  
            {/* Excel File Preview */}
            {fileName.match(/\.(xls|xlsx)$/) && (
              <div>
                <h4>Excel File Preview:</h4>
                <pre>{fileContent}</pre>
              </div>
            )}
  
            {/* Unsupported File Types */}
            {!fileType.startsWith("image/") &&
              !fileType.startsWith("video/") &&
              fileType !== "application/pdf" &&
              !fileName.match(/\.(doc|docx|xls|xlsx)$/) && (
                <p>
                  <a href={preview} download>
                    Download File
                  </a>{" "}
                  (Preview not available)
                </p>
              )}
          </div>
        )}
      </div>
    )
}


export default Preview