import React from 'react';
import emptydata from '../../Assests/EmptyDataBox.svg'
import emptydata2 from '../../Assests/EmptyBox2.svg'
import "./EmptyData.css"

const EmptyData = () => {
  return (
    <div className='empty-box-container'>
      <img src={emptydata2}/>
      <div className='empty-box-content-container'>
      <p className='empty-box-header'>No Data</p>
      <p className='empty-box-text'>Looks Like You Don't Have Any Data to View</p>
      </div>
    </div>
  )
}

export default EmptyData
