import React, { useState } from "react";
import Modal from "react-modal";
import Preview from "../DocumentPreview/Preview";
import CreateCandidate from "../CreateCandidate/CreateCandidate";


const ParseResume = () =>{
  
    const [file,setfile] = useState()
    return(
      <div style={{ marginTop: 100 }}>
      <input type="file" onChange={(e) => setfile(e.target.files[0])} />
    
      {file && (
        <div style={{ display: "flex", height: "calc(100vh - 100px)", overflow: "hidden" }}>
          {/* Fixed Left Preview Section */}
          <div style={{ width: "50%", height: "100%", overflow: "hidden", position: "sticky", top: 100 }}>
            <Preview filetobePreview={file} />
          </div>
    
          {/* Scrollable Right Form Section */}
          <div
            style={{
              width: "50%",
              height: "100%",
              overflowY: "auto",
              boxSizing: "border-box",
              overflowX:"hidden"
            }}
          >
            <CreateCandidate fileWanttobeParse={file} />
          </div>
        </div>
      )}
    </div>
    
    )
}


export default ParseResume