import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const env = "test";

export const BASE_URL =
  env === "test"
    ? "https://hiremagic.in/api/"
    : env === "production"
    ? "https://bandyl.com/api/"
    : "https://3j6lq1k0-3001.inc1.devtunnels.ms/api/";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    // credentials :true
  }),

  endpoints: () => ({}),
});

export default apiSlice;
