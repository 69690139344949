import React, { useEffect, useState, useRef, useCallback } from "react";
import Tree from "react-d3-tree";
import { useGetHierachyQuery } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import { X, Maximize2, Minimize2 } from "lucide-react";

const Reportees = () => {
  const isAuth = useSelector((state) => state.auth);
  const [hierarchy, setHierarchy] = useState([]);
  const [treeData, setTreeData] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState({});
  const [lastClickedNodeId, setLastClickedNodeId] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const treeRef = useRef(null);

  const { data: hierachyResponse, isSuccess } = useGetHierachyQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  useEffect(() => {
    if (isSuccess && hierachyResponse?.status) {
      setHierarchy(hierachyResponse.message || []);
    }
  }, [isSuccess]);

  const toggleExpand = (h_id) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [h_id]: !prev[h_id],
    }));
  };

  const countAllDescendants = (parentId) => {
    let count = 0;
    const children = hierarchy.filter((node) => node.parent_id === parentId);
    for (const child of children) {
      count += 1;
      count += countAllDescendants(child.h_id);
    }
    return count;
  };

  const buildTree = useCallback(
    (parentId = null) => {
      return hierarchy
        .filter((node) => node.parent_id === parentId)
        .map((node) => {
          const children = buildTree(node.h_id);
          const hasChildren = children.length > 0;

          return {
            name: node.label,
            nodeId: node.h_id,
            attributes: {
              Role: node.value,
            },
            childCount: countAllDescendants(node.h_id),
            children: expandedNodes[node.h_id] ? children : undefined,
            _collapsed: !expandedNodes[node.h_id],
            toggler: hasChildren ? () => toggleExpand(node.h_id) : undefined,
          };
        });
    },
    [hierarchy, expandedNodes]
  );

  useEffect(() => {
    const root = hierarchy.find((node) => node.parent_id === null);
    if (root) {
      const children = buildTree(root.h_id);
      setTreeData({
        name: root.label,
        nodeId: root.h_id,
        attributes: {
          Role: root.value,
        },
        childCount: countAllDescendants(root.h_id),
        children: expandedNodes[root.h_id] ? children : undefined,
        _collapsed: !expandedNodes[root.h_id],
        toggler: () => toggleExpand(root.h_id),
      });
    }
  }, [hierarchy, expandedNodes, buildTree]);

  const findNodeDatum = (node, targetId) => {
    if (!node) return null;
    if (node.nodeId === targetId) return node;
    if (node.children) {
      for (const child of node.children) {
        const found = findNodeDatum(child, targetId);
        if (found) return found;
      }
    }
    return null;
  };

  useEffect(() => {
    if (!treeRef.current || !treeData || !lastClickedNodeId) return;
    const nodeDatum = findNodeDatum(treeData, lastClickedNodeId);
    if (nodeDatum) {
      treeRef.current.centerNode(nodeDatum);
    }
  }, [treeData, expandedNodes, lastClickedNodeId]);

  const handleExpand = (nodeDatum) => {
    toggleExpand(nodeDatum.nodeId);
    setLastClickedNodeId(nodeDatum.nodeId);
  };

  const renderCustomNode = ({ nodeDatum, toggleNode }) => {
    const hasChildren = nodeDatum.children || nodeDatum._collapsed;
    const childCount = nodeDatum.childCount || 0;

    return (
      <g>
        <foreignObject width="200" height="130" x="-100" y="-65">
          <div
            data-nodeid={nodeDatum.nodeId}
            style={{
              background: "#FFFFFF",
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
              padding: "20px",
              textAlign: "center",
              fontFamily: "Poppins, sans-serif",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "6px",
              width: "200px",
              height: "130px",
              boxSizing: "border-box",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              transition: "transform 0.2s",
              position: "relative",
            }}
            onClick={() => {
              toggleNode();
              handleExpand(nodeDatum);
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = "scale(1.02)")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.transform = "scale(1)")
            }
          >
            <div
              style={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#1E88E5",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {nodeDatum.name}
            </div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#A4A4A4",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {nodeDatum.attributes?.Role}
            </div>

            {hasChildren && nodeDatum._collapsed && childCount > 0 && (
              <div
                style={{
                  position: "absolute",
                  bottom: "0px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#1E88E5",
                  color: "#FFFFFF",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  width: "28px",
                  height: "28px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins, sans-serif",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleNode();
                  handleExpand(nodeDatum);
                }}
              >
                {childCount}
              </div>
            )}
          </div>
        </foreignObject>
      </g>
    );
  };

  return (
    <div
      style={{
        width: isFullscreen ? "100vw" : "100%",
        height: isFullscreen ? "100vh" : "600px",
        position: isFullscreen ? "fixed" : "relative",
        top: isFullscreen ? 0 : "unset",
        left: isFullscreen ? 0 : "unset",
        backgroundColor: "#F5F5F5",
        zIndex: isFullscreen ? 1000 : 1,
        border: isFullscreen ? "none" : "1px solid #ddd",
        boxShadow: isFullscreen ? "0 0 10px rgba(0,0,0,0.3)" : "none",
      }}
    >
      <div
        style={{
          height: "50px",
          backgroundColor: "#1E88E5",
          color: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
          fontFamily: "Poppins, sans-serif",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        <span>Organization Chart</span>
        <div>
          <button
            onClick={() => setIsFullscreen((prev) => !prev)}
            style={{
              background: "transparent",
              border: "none",
              color: "#fff",
              cursor: "pointer",
              marginLeft: "10px",
            }}
          >
            {isFullscreen ? (
              <>
                <Minimize2 size={18} style={{ marginRight: 6 }} />
                Exit Full Screen
              </>
            ) : (
              <>
                <Maximize2 size={18} style={{ marginRight: 6 }} />
                Show in Full Screen
              </>
            )}
          </button>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: isFullscreen ? "calc(100vh - 50px)" : "calc(100% - 50px)",
          overflow: "hidden",
        }}
      >
        {treeData && (
          <Tree
            ref={treeRef}
            data={treeData}
            orientation="vertical"
            renderCustomNodeElement={renderCustomNode}
            zoomable
            collapsible={false}
            separation={{ siblings: 2, nonSiblings: 2.5 }}
            pathFunc="step"
            enableLegacyTransitions={true}
            nodeSize={{ x: 130, y: 200 }}
            pathProps={{
              stroke: "#1E88E5",
              strokeWidth: "2px",
              fill: "none",
            }}
            translate={isFullscreen?{ x: 700, y: 200 }:{ x: 550, y: 200 }}
          />
        )}
      </div>
    </div>
  );
};

export default Reportees;
