import React, { useEffect, useState } from "react";
import {
  useCreateGroupsMutation,
  useGetGroupsQuery,
  useGetUsersQuery,
} from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import { FaUserGroup, FaEdit, FaTrash } from "react-icons/fa6";
import { FaSearch, FaUserEdit } from "react-icons/fa";
import "./CreateGroups.css";
import Select from "react-select";
import { showToast } from "../../../ToastContainer/ToastProvider";

const CreateGroups = () => {
  const isAuth = useSelector((state) => state.auth);
  const [searchQuery, setSearchQuery] = useState("");
  const [AllGroups, setAllGroups] = useState();
  const [AddUserForm, setAddUserForm] = useState(false);
  const [groupData, setGroupData] = useState({
    group_name: "",
    group_head: "",
    role_id: "",
    role_name: "",
    user_id: "",
    item: [],
  });

  const [dropdown, setDropdown] = useState([]);
  const [multiSelectData, setMultiSelectData] = useState([]);

  const { data: Groups, isSuccess: GroupSuccess } = useGetGroupsQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  useEffect(() => {
    if (Groups?.status) {
      setAllGroups(Groups?.message || []);
    }
  }, [GroupSuccess]);

  // console.log("Groups===>", Groups);
  const { data, isSuccess } = useGetUsersQuery({
    domain_name: isAuth?.user?.domain_name,
  });
  const [createGroup] = useCreateGroupsMutation();
  const handleSubmit = async () => {
    try {
      const response = await createGroup({
        domain_name: isAuth?.user?.domain_name,
        data: groupData,
        user: `${isAuth?.user?.firstname} ${isAuth?.user?.lastname}`,
      });
      // console.log(response);
      if(response)
        showToast(response?.data?.status,response?.data?.message)
      setGroupData({
        group_name: "",
        group_head: "",
        role_id: "",
        role_name: "",
        user_id: "",
        item: [],
      });
    } catch (err) {
      if(err)
        showToast(err?.data?.status,err?.data?.message)
      setGroupData({
        group_name: "",
        group_head: "",
        role_id: "",
        role_name: "",
        user_id: "",
        item: [],
      });
    }
  };
  // Populate dropdown when data is fetched
  useEffect(() => {
    if (isSuccess && data?.status) {
      setDropdown(data?.message || []);
    }
  }, [isSuccess, data]);

  // Update selected user's details & filter out from multi-select options
  useEffect(() => {
    if (dropdown.length > 0 && groupData.user_id) {
      const selectedUser = dropdown.find(
        (item) => item?.value === groupData?.user_id
      );
      if (selectedUser) {
        setGroupData((prev) => ({
          ...prev,
          group_head: selectedUser?.label,
          role_name: selectedUser?.role_name,
          role_id: selectedUser?.role_id,
        }));

        setMultiSelectData(
          dropdown.filter((item) => item?.value !== groupData?.user_id)
        );
      }
    }
  }, [groupData.user_id, dropdown]);

  // console.log(groupData)

  const filteredGroups = (AllGroups || []).filter((group) =>
    group?.group_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
    <h3>Create group</h3>
      <div className="setting-create-group-container">
        <div className="setting-create-group-search">
          <FaSearch className="setting-creategroup-search-icon" />
          <input
            type="text"
            placeholder="Search..."
            className="Setting-createsearch"
            value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} 
            //    value={searchSettings}
            //    onChange={(e) => setSearchSettings(e.target.value)}
          />
        </div>
        <div className="setting-create-group-button">
          <button
          className="ctn"
           onClick={() => setAddUserForm(true)}>
            Add Group <FaUserEdit className="setting-create-group-icon" />
          </button>
          {AddUserForm && (
            <div className="hidden-group-form-overlay">
            <div className={`hidden-group-form show-group-form`}>
              <div className="Access-group-role-modal-header">
                Add Group
                <button onClick={() => setAddUserForm(false)}>✖</button>
              </div>
              <div className="Access-group-role-modal-body">
                <input
                  type="text"
                  placeholder="Enter the Group Name"
                  value={groupData.group_name}
                  onChange={(e) =>
                    setGroupData((prev) => ({
                      ...prev,
                      group_name: e.target.value,
                    }))
                  }
                />

                {/* Group Head Dropdown (Shown only when Group Name is entered) */}
                {/* {groupData.group_name.length > 0 && ( */}
                <select
                  value={groupData?.user_id}
                  onChange={(e) =>
                    setGroupData((prev) => ({
                      ...prev,
                      user_id: e.target.value,
                    }))
                  }
                >
                  <option value="">Select Group Head</option>
                  {dropdown.map((item, i) => (
                    <option key={i} value={item?.value}>
                      {item?.label} ({item?.role_name})
                    </option>
                  ))}
                </select>
                {/* // )} */}

                {/* Multi-Select for Additional Users (Shown only when Group Head is selected) */}
                {/* {groupData?.group_head && ( */}
                  <div>
                    <Select
                      onChange={(selected) =>
                        setGroupData((prev) => ({ ...prev, item: selected }))
                      }
                      isMulti
                      options={multiSelectData}
                    />
                  </div>
                {/* )} */}
              </div>
              <div className="Access-group-role-modal-footer">
                <button
                  className="Access-group-role-cancel-button"
                  onClick={() => setAddUserForm(false)}
                >
                  Cancel
                </button>
                <button
                  className="Access-group-role-save-button"
                  onClick={handleSubmit}
                >
                  Create Group
                </button>
              </div>
            </div>
            </div>
          )}
        </div>
      </div>
      <div className="Create-group-container">
        {filteredGroups.length > 0 ? (
          filteredGroups.map((item, i) => (
            <div className="Create-group-card" key={i}>
              <div className="Create-group-header">
                <h3>{item?.group_name}</h3>
                <div className="Create-group-icons"></div>
              </div>
              <div className="Create-group-body">
                <div className="Create-group-info">
                  <p className="Create-group-label">Team Lead</p>
                  <p className="Create-group-lead">{item?.group_lead}</p>
                  <p className="Create-group-label">
                    Members <b>{item?.team?.length || 0}</b>
                  </p>
                </div>
                <div className="Create-group-team-icon">
                  <FaUserGroup />
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="no-results">No groups found</p>
        )}
      </div>
    </>
  );
};

export default CreateGroups;
