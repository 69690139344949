import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetCandidateSkillQuery,
  useGetCandidateUpdateByIdQuery,
  useGetCustomerJobUpdateByIdQuery,
  usePosCustomerJobUpdateByIdMutation,
  useUpdateCandidateMutation,
  useUpdateTemplateByIdMutation,
} from "../../Redux/API/atsSlice";
import DynamicTextInput from "../DynamicInputs/DynamicTextInput";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { domAnimation } from "framer-motion";
import usePrivilege from "../Privileges/Privileges";
import AtsDashBoardHeader from "../AtsDashBoardHeader/AtsDashBoardHeader";

function EditCandidate() {
  const { candidate_id } = useParams();
  const MovableInput = useRef();
  const isAuth = useSelector((state) => state.auth);
  const [customInputs, setCustomInput] = useState([]);
  const defaultFieldCount = {
    Education: 5,
    Experience: 6,
    Address: 6,
    Certifications: 3,
  };

  const [EditJob] = usePosCustomerJobUpdateByIdMutation();
  const [Remove, setRemove] = useState(false);
  const [addNew, setaddNew] = useState({
    experience: "",
    education: "",
    address: "",
    certification: "",
  });

  const [params] = useSearchParams();

  //  console.log(id)
  const { data, isSuccess, refetch } = useGetCandidateUpdateByIdQuery(
    {
      domain_name: isAuth?.user?.domain_name,
      candidate_id: candidate_id,
    },
    {
      skip: !candidate_id,
    }
  );
  // console.log("data", data);
  const privilege = usePrivilege("Candidate Update");
  const { data: CandidateSkill, refetch: CandidateSkillRefetch } =
    useGetCandidateSkillQuery({
      domain_name: isAuth?.user?.domain_name,
      candidate_id: candidate_id,
    });
  console.log("Skil", CandidateSkill);
  useEffect(() => {
    refetch();
    if (data?.status) {
      setCustomInput(data?.message || []);
      setCustomInput((prev) => {
        return prev.map((item) =>
          item?.section === "Skills"
            ? {
                ...item,
                fields: item?.fields.map((ele) => ({
                  ...ele,
                  valueOftheField: CandidateSkill?.status
                    ? JSON.stringify(CandidateSkill?.message)
                    : [],
                })),
              }
            : item
        );
      });
    }
  }, [isSuccess, candidate_id]);

  const handleNewFields = (e, type, index) => {
    e.preventDefault();
    setCustomInput((prev) => {
      return prev.map((item) => {
        if (item.section === type) {
          const newfields = item?.fields
            ?.slice(0, index)
            .map((ele, i) => ({ ...ele, valueOftheField: "", element_id: "" }));
          return {
            ...item,
            fields: [...item.fields, ...newfields],
          };
        }
        return item;
      });
    });
  };
  const handleDeleteFieldGroup = (sectionType, groupSize) => {
    setCustomInput((prev) =>
      prev.map((item) => {
        if (item.section === sectionType) {
          const defaultCount = defaultFieldCount[sectionType] || 0;
          if (item.fields.length > defaultCount) {
            const updatedFields = item.fields.slice(
              0,
              item.fields.length - groupSize
            );
            return {
              ...item,
              fields: updatedFields,
            };
          }
        }
        return item;
      })
    );
  };

  const [updateCandidate] = useUpdateCandidateMutation();
  const handleListener = async () => {
    try {
      const response = await updateCandidate({
        domain_name: isAuth?.user?.domain_name,
        candidate_id: candidate_id,
        data: customInputs,
        user_id: isAuth?.user?.user_id,
      });
      console.log("Response==>>", response);
    } catch (err) {
      console.log(err);
    }
  };
  console.log("customeinputs", customInputs);

  return (
    <>
      <AtsDashBoardHeader />
      <div>
        <div
          className="create-job-container"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div>
            <div>
              {customInputs.map((parent_item, parent_index) => (
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "15pt",
                      outline: "none",
                      border: "none",
                    }}
                  >
                    {parent_item?.section}
                  </p>
                  {parent_item?.section === "Education" && (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        onClick={(e) => handleNewFields(e, "Education", 5)}
                      >
                        Add New Education
                      </button>
                    </div>
                  )}

                  {parent_item?.section === "Experience" && (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        onClick={(e) => handleNewFields(e, "Experience", 6)}
                      >
                        Add New Experience
                      </button>
                    </div>
                  )}

                  {parent_item?.section === "Address" && (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <button onClick={(e) => handleNewFields(e, "Address", 6)}>
                        Add New Address
                      </button>
                    </div>
                  )}

                  {parent_item?.section === "Certifications" && (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        onClick={(e) => handleNewFields(e, "Certifications", 3)}
                      >
                        Add New Certifications
                      </button>
                    </div>
                  )}

                  <div
                    style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                  >
                    {parent_item?.section === "Experience"
                      ? [
                          ...Array(Math.ceil(parent_item.fields.length / 6)),
                        ].map((_, groupIndex) => {
                          const groupFields = parent_item.fields.slice(
                            groupIndex * 6,
                            groupIndex * 6 + 6
                          );
                          return (
                            <div
                              key={groupIndex}
                              className="group-box"
                              style={{
                                border: "1px solid #ddd",
                                borderRadius: "10px",
                                padding: "16px",
                                marginBottom: "20px",
                                width: "100%",
                              }}
                            >
                              {groupIndex > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      margin: 0,
                                    }}
                                  >
                                    Experience Group {groupIndex}
                                  </p>
                                  <button
                                    onClick={() =>
                                      handleDeleteFieldGroup("Experience", 6)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "12px",
                                }}
                              >
                                {groupFields.map((item, i) => (
                                  <div
                                    key={i}
                                    className="bg-white p-4 rounded-lg shadow-md flex items-center justify-between"
                                    style={{ width: "300px" }}
                                  >
                                    <DynamicTextInput
                                      parentInd={parent_index}
                                      index={groupIndex * 6 + i}
                                      {...item}
                                      value={customInputs}
                                      customInputs={customInputs}
                                      onChangeCustomInput={setCustomInput}
                                      onChangeValue={setCustomInput}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })
                      : parent_item?.section === "Education"
                      ? [
                          ...Array(Math.ceil(parent_item.fields.length / 5)),
                        ].map((_, groupIndex) => {
                          const groupFields = parent_item.fields.slice(
                            groupIndex * 5,
                            groupIndex * 5 + 5
                          );
                          return (
                            <div
                              key={groupIndex}
                              className="group-box"
                              style={{
                                border: "1px solid #ddd",
                                borderRadius: "10px",
                                padding: "16px",
                                marginBottom: "20px",
                                width: "100%",
                              }}
                            >
                              {groupIndex > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      margin: 0,
                                    }}
                                  >
                                    Education {groupIndex}
                                  </p>
                                  <button
                                    onClick={() =>
                                      handleDeleteFieldGroup("Education", 5)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "12px",
                                }}
                              >
                                {groupFields.map((item, i) => (
                                  <div
                                    key={i}
                                    className="bg-white p-4 rounded-lg shadow-md flex items-center justify-between"
                                    style={{ width: "300px" }}
                                  >
                                    <DynamicTextInput
                                      parentInd={parent_index}
                                      index={groupIndex * 5 + i}
                                      {...item}
                                      value={customInputs}
                                      customInputs={customInputs}
                                      onChangeCustomInput={setCustomInput}
                                      onChangeValue={setCustomInput}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })
                      : parent_item?.section === "Address"
                      ? [
                          ...Array(Math.ceil(parent_item.fields.length / 6)),
                        ].map((_, groupIndex) => {
                          const groupFields = parent_item.fields.slice(
                            groupIndex * 6,
                            groupIndex * 6 + 6
                          );
                          return (
                            <div
                              key={groupIndex}
                              className="group-box"
                              style={{
                                border: "1px solid #ddd",
                                borderRadius: "10px",
                                padding: "16px",
                                marginBottom: "20px",
                                width: "100%",
                              }}
                            >
                              {groupIndex > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      margin: 0,
                                    }}
                                  >
                                    Address {groupIndex}
                                  </p>
                                  <button
                                    onClick={() =>
                                      handleDeleteFieldGroup("Address", 6)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "12px",
                                }}
                              >
                                {groupFields.map((item, i) => (
                                  <div
                                    key={i}
                                    className="bg-white p-4 rounded-lg shadow-md flex items-center justify-between"
                                    style={{ width: "300px" }}
                                  >
                                    <DynamicTextInput
                                      parentInd={parent_index}
                                      index={groupIndex * 6 + i}
                                      {...item}
                                      value={customInputs}
                                      customInputs={customInputs}
                                      onChangeCustomInput={setCustomInput}
                                      onChangeValue={setCustomInput}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })
                      : parent_item?.section === "Certifications"
                      ? [
                          ...Array(Math.ceil(parent_item.fields.length / 3)),
                        ].map((_, groupIndex) => {
                          const groupFields = parent_item.fields.slice(
                            groupIndex * 3,
                            groupIndex * 3 + 3
                          );
                          return (
                            <div
                              key={groupIndex}
                              className="group-box"
                              style={{
                                border: "1px solid #ddd",
                                borderRadius: "10px",
                                padding: "16px",
                                marginBottom: "20px",
                                width: "100%",
                              }}
                            >
                              {groupIndex > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      margin: 0,
                                    }}
                                  >
                                    Certifications Group {groupIndex}
                                  </p>
                                  <button
                                    onClick={() =>
                                      handleDeleteFieldGroup(
                                        "Certifications",
                                        3
                                      )
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "12px",
                                }}
                              >
                                {groupFields.map((item, i) => (
                                  <div
                                    key={i}
                                    className="bg-white p-4 rounded-lg shadow-md flex items-center justify-between"
                                    style={{ width: "300px" }}
                                  >
                                    <DynamicTextInput
                                      parentInd={parent_index}
                                      index={groupIndex * 3 + i}
                                      {...item}
                                      value={customInputs}
                                      customInputs={customInputs}
                                      onChangeCustomInput={setCustomInput}
                                      onChangeValue={setCustomInput}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })
                      : parent_item?.fields &&
                        parent_item.fields.map((item, i) => (
                          <div
                            key={i}
                            className="bg-white p-4 rounded-lg shadow-md mb-3 flex items-center justify-between"
                            style={{ width: "300px" }}
                          >
                            <DynamicTextInput
                              parentInd={parent_index}
                              index={i}
                              {...item}
                              value={customInputs}
                              customInputs={customInputs}
                              onChangeCustomInput={setCustomInput}
                              onChangeValue={setCustomInput}
                            />
                          </div>
                        ))}
                  </div>
                </div>
              ))}
            </div>
            <div style={{ marginTop: "2%", display: "flex", gap: "1%" }}>
              <button
                onClick={handleListener}
                disabled={!privilege.includes(isAuth?.user?.user_id)} // Disable if user is unauthorized
                style={{
                  cursor: privilege.includes(isAuth?.user?.user_id)
                    ? "pointer"
                    : "not-allowed",
                  opacity: privilege.includes(isAuth?.user?.user_id) ? 1 : 0.6,
                }}
                className="edit-save-changes-button"
              >
                Update Candidate
              </button>
              <button className="edit-cancel-button">
                <a href="/ats/dashboard/?type=candidates">Cancel</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCandidate;
