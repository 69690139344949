import React, { useState, useEffect } from "react";
import "./CompanyDetailForm.css";
import ReactQuill from "react-quill";
import { useGetCompanyDetailsQuery, useUpdateCompanyDetailsMutation, useUpdateLocaleInfoMutation } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import { Edit, Save } from "lucide-react";
import { FaEdit } from "react-icons/fa";
import { useGetCountryListQuery, useGetCurrencyQuery, useGetTimeZoneQuery } from "../../../../Redux/API/homeSlice";
import { MdOutlineCancel } from "react-icons/md";
import { forEachChild } from "typescript";
import { showToast } from "../../../ToastContainer/ToastProvider";

const CompanyDetailForm = () => {
  const [activeTab, setActiveTab] = useState("general-details");
  const [isEditing, setIsEditing] = useState(false);
  const [CountryListData, setCountryList] = useState();
  const [timeFormat, setTimeFormat] = useState("24");
  const isAuth = useSelector((state) => state.auth);


  const {
    data: TimeZones,
  } = useGetTimeZoneQuery()
  const {
    data: CountryList,
    isSuccess: CountrySuccess,
    refetch: CountryRefetch,
  } = useGetCountryListQuery();

  const { data, refetch } = useGetCompanyDetailsQuery({
    domain_name: isAuth?.user?.domain_name
  });

  console.log("data", data)

  useEffect(() => {
    refetch();
  }, [])

  // Initial Company Data from API
  const CompanyData = data?.message[0] || {};

  const [formData, setFormData] = useState({
    company_name: "",
    display_name: "",
    website: "",
    contact_number: "",
    hsn_sac_code: "",
    pan_number: "",
    description: "",
    country_code: "",
    origin: "",
    currency: "",
    date_format: "",
    timezone: "",
    time_format: ""
  });
  // console.log("formdataa",formData);


  // const  {
  //   data:CountryList
  // } = useGetCountryListQuery()
  useEffect(() => {
    if (data?.status) {
      setFormData({
        company_name: CompanyData.company_name || "",
        display_name: CompanyData.display_name || "",
        website: CompanyData.website || "",
        contact_number: CompanyData.contact_number || "",
        hsn_sac_code: CompanyData.hsn_sac_code || "",
        pan_number: CompanyData.pan_number || "",
        description: CompanyData.description || "",
        country_code: CompanyData.country_code || "",
        origin: CompanyData.origin || "",
        currency: CompanyData.currency || "",
        date_format: CompanyData.date_format || "",
        timezone: CompanyData.timezone || "",
        time_format: CompanyData.time_format || ""
      });
    }
  }, [CompanyData]);

  const {
    data: Currency, isSuccess: CurrencySuccess
  } = useGetCurrencyQuery()
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDescriptionChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const [updateCompanyDetails] = useUpdateCompanyDetailsMutation();

  const handleSave = async () => {
    try {
      const response = await updateCompanyDetails({
        data: formData,
        domain_name: isAuth?.user?.domain_name,
      }).unwrap();

      // console.log("Company details updated successfully ===>", response);
      if (response) {
        // console.log(response.message)
        showToast(response.status, response.message)
      }

      setFormData(response.updatedCompanyDetails || formData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating company details:", error);
    }
  };

  const [updateLocale] = useUpdateLocaleInfoMutation()
  const handleLocaleUpdate = async () => {
    const obj = {
      origin: formData?.origin,
      currency: formData?.currency,
      date_format: formData?.date_format,
      timezone: formData?.timezone,
      time_format: formData?.time_format
    }
    try {
      const res = await updateLocale({
        domain_name: isAuth?.user?.domain_name,
        data: obj
      })
      if (res.data.status) {
        console.log(res.data)
        showToast(res.data.status, res.data.message)
        // setFormData({
        //   company_name: "",
        //   display_name: "",
        //   website: "",
        //   contact_number: "",
        //   hsn_sac_code: "",
        //   pan_number: "",
        //   description: "",
        //   country_code: "",
        //   origin: "",
        //   currency: "",
        //   date_format: "",
        //   timezone: "",
        //   time_format: ""
        // })
      }
      // console.log("res", res)
    } catch (err) {
      
    }
  }

  const [dataFormat, setDateformat] = useState([
    "dd/mm/yy",
    "mm/dd/yy",
    "yyyy-mm-dd",
    "yyyy/mm/dd",
    "mm dd yyyy",
    "yyy-mm-ddyhh:mm:ssz"
  ])

  return (
    <div className="company-details-container">
      <div className="setting-company-tabs">
        <button
          className={activeTab === "general-details" ? "active" : ""}
          onClick={() => setActiveTab("general-details")}
        >
          General Details
        </button>
        {/* <button
          className={activeTab === "branding" ? "active" : ""}
          onClick={() => setActiveTab("branding")}
        >
          Branding
        </button> */}
        <button
          className={activeTab === "locale-information" ? "active" : ""}
          onClick={() => setActiveTab("locale-information")}
        >
          Locale Information
        </button>
      </div>

      {/* General Details Tab */}
      {activeTab === "general-details" && (
        <>
          <div style={{ display: "flex", gap: "1%", alignItems: "center", backgroundColor: "transparent" }}>
            <h4 style={{ color: "black" }}>General Information</h4>
            {!isEditing && (
              <button className="company-edit-btn" style={{ backgroundColor: "transparent" }} onClick={handleEdit}>
                <FaEdit className="company-setting-edit" />
              </button>
            )}
          </div>

          <div className="general-details-section">
            <div className="company-detail-form-group">
              <div className="comapany-input-group">
                <label>Company Name</label>
                <input
                  type="text"
                  name="company_name"
                  className="company-input-field"
                  value={formData.company_name}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
              <div className="comapany-input-group">
                <label>Display Name</label>
                <input
                  type="text"
                  name="display_name"
                  className="company-input-field"
                  value={formData.display_name}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
              <div className="comapany-input-group">
                <label>Website</label>
                <input
                  type="text"
                  name="website"
                  className="company-input-field"
                  value={formData.website}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
            </div>

            <div className="company-detail-form-group">

              <div className="comapany-input-group">
                <label>Company Contact</label>
                <div className="company-mobile-input">
                  <select
                    value={formData.country_code}
                    onChange={(e) => setFormData({ ...formData, country_code: e.target.value })}
                  >
                    {CountryList?.status &&
                      CountryList?.message.map((item, i) => (
                        <option key={i} value={item?.phonecode}>
                          {item?.phonecode}
                        </option>
                      ))}
                  </select>

                  <input
                    type="text"
                    name="contact_number"
                    className="company-input-field"
                    value={formData.contact_number}
                    onChange={handleChange}
                    readOnly={!isEditing}
                  />
                </div>
              </div>
              <div className="comapany-input-group">
                <label>HSN/SAC Code</label>
                <input
                  type="text"
                  name="hsn_sac_code"
                  className="company-input-field"
                  value={formData.hsn_sac_code}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
              <div className="comapany-input-group">
                <label>PAN Number</label>
                <input
                  type="text"
                  name="pan_number"
                  className="company-input-field"
                  value={formData.pan_number}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
            </div>



            <div className=" setting-description">
              <label>Description</label>
              <ReactQuill value={formData.description} onChange={handleDescriptionChange} theme="snow" readOnly={!isEditing} />
            </div>

            {/* Save & Cancel Buttons at Bottom */}
            {isEditing && (
              <div className="company-form-actions">
                <button className="company-cancel-btn" onClick={() => setIsEditing(false)}>
                  Cancel
                </button>
                <button className="company-save-btn" onClick={handleSave}>
                  Save Changes
                </button>
              </div>
            )}
          </div>
        </>
      )}


      {/* Branding Tab */}
      {activeTab === "branding" && (
        <div className="branding-section">
          <div className="branding-upload-container">
            <div className="upload-box">
              <label>Company Logo</label>
              <div className="drop-area">
                <p>Drag & drop or click to choose files</p>
                <span>Size: 512px x 512px</span>
              </div>
            </div>
            <div className="upload-box">
              <label>Social Share Image</label>
              <div className="drop-area">
                <p>Drag & drop or click to choose files</p>
                <span>Size: 512px x 512px</span>
              </div>
            </div>
          </div>

          <div className="branding-form-actions">
            <button className="branding-cancel-btn">Cancel</button>
            <button className="branding-save-btn">Save</button>
          </div>
        </div>
      )}

      {/* Locale Information Tab */}
      {activeTab === "locale-information" && (
        <div className="locale-info-section">
          <div className="locale-row">
            <div className="locale-field">
              <label>Country of Origin</label>
              <select value={formData?.origin} onChange={(e) => {
                setFormData({ ...formData, origin: e.target.value })
              }}>
                {CountryList?.message && CountryList?.message?.map((item, i) => {
                  return (
                    <option value={item?.name}>{item?.name}</option>
                  )
                })}
              </select>
            </div>
            <div className="locale-field">
              <label>Currency</label>
              {/* <input type="text" value="INR" readOnly /> */}
              <select value={formData?.currency} onChange={(e) => {
                setFormData({ ...formData, currency: e.target.value })
              }}>
                {
                  Currency?.message && Currency?.message?.map((item, i) => {
                    return (
                      <option value={`${item?.name}  ${item?.code} (${item?.symbol})`}>{item?.name}  {item?.code} ({item?.symbol})</option>
                    )
                  })
                }
              </select>
            </div>
          </div>

          <div className="locale-row">
            <div className="locale-field">
              <label>Time Zone</label>
              <select value={formData.timezone} onChange={(e) => setFormData({ ...formData, timezone: e.target.value })}>
                {
                  TimeZones?.message && TimeZones?.message?.map((item, i) => {
                    return (
                      <option value={`${item?.TimeZone} ${item?.UTC_offset} ${item?.UTC_DST_offset}`}>{item?.TimeZone} {item?.UTC_offset} {item?.UTC_DST_offset}</option>
                    )
                  })
                }
                {/* <option>(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                <option>(UTC-08:00) Pacific Time (US & Canada)</option>
                <option>(UTC+00:00) London</option>
                <option>(UTC+01:00) Berlin, Paris</option> */}
              </select>
            </div>
            <div className="locale-field">
              <label>Date Format</label>
              <select value={formData?.date_format} onChange={(e) => setFormData({ ...formData, date_format: e.target.value })}>
                {/* <option>dd/mm/yy</option>
                <option>mm/dd/yy</option>
                <option>yyyy-mm-dd</option>
                <option>yyyy/mm/dd</option>
                <option>mm dd yyyy</option>
                <option>yyy-mm-ddyhh:mm:ssz</option> */}
                {
                  dataFormat?.map((item, i) => {
                    return (
                      <option value={item}>{item}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>

          <div className="locale-time-format">
            <label>Time Format</label>
            <div className="time-format-options">
              <label>
                <input type="radio" value={formData.time_format} checked={formData.time_format === "24"} onChange={() => {
                  setTimeFormat("24")
                  setFormData({ ...formData, time_format: "24" })
                }} />
                24 Hours
              </label>
              <label>
                <input type="radio" value={formData.time_format} checked={formData.time_format === "12"} onChange={() => {
                  setTimeFormat("12")
                  setFormData({ ...formData, time_format: "12" })
                }} />
                12 Hours
              </label>
            </div>
          </div>

          <div className="locale-form-actions">
            <button className="locale-cancel-btn">Cancel</button>
            <button className="locale-save-btn" onClick={handleLocaleUpdate}>Save</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyDetailForm;
