import React, { useState } from "react";
import "./WinspireHeader.css";
import { FaBell, FaEnvelope, FaUserCircle } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/API/authSlice";
import { BASE_URL } from "../../Redux/API/apiSlice";
import User2 from '../../Assests/user.png'
import {
  useGetCustomerMenuItemQuery,
  useGetUserProfileQuery,
} from "../../Redux/API/atsSlice";
import { useGetSettingsQuery } from "../../Redux/API/atsSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const WinspireHeader = () => {
  const [menuEnable, setMenuEnable] = useState([]);
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth);
  const user_id = isAuth?.user?.user_id;
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const { data } = useGetCustomerMenuItemQuery({
    domain_name: isAuth?.user.domain_name,
  });

  const main_header =
    data?.message?.find((section) => section.header_name === "main_header")
      ?.items || [];
  const handleLogOut = (e) => {
    e.preventDefault();

    navigate("/");
    setTimeout(() => {
      dispatch(logout());
    }, 1000);
  };

  const {
    data: settingsData,
    isSuccess: settingsIsSuccess,
    refetch: settingsRefetch,
  } = useGetSettingsQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  useEffect(() => {
    if (settingsIsSuccess && settingsData?.message) {
      const mail = settingsData.message.find(
        (item) => item.name_of_the_menu === "is_mail"
      );
      const notification = settingsData.message.find(
        (item) => item.name_of_the_menu === "is_notifications"
      );

      setMenuEnable({
        is_mail: mail?.is_enabled || 0,
        is_notification: notification?.is_enabled || 0,
      });
    }
  }, [settingsIsSuccess, settingsData]);

  const { data: profiledata, refetch:Profilerefetch } = useGetUserProfileQuery({ user_id:isAuth?.user?.user_id });

  console.log("pro",profiledata)
  useEffect(()=>{
    Profilerefetch()
  },[])
  console.log("profile==>", profiledata);
  return (
    <div className="winspire-header">
      {/* Left Section - User Profile */}
      <div className="winspire-header-left">
        {isAuth?.isAuthenticated ? (
          <div className="user-info">
            <div
              className="profile-icon"
              onClick={() => setShowMenu(!showMenu)}
            >
              <img
                src={
                  profiledata?.message?.profile_pic?.startsWith("public")
                    ? `${BASE_URL}${profiledata?.message?.profile_pic}`
                    : profiledata?.message?.profile_pic || User2
                }
                alt="Profile"
                className="winspireheader-profile-icon-img"
              />
              <span className="welcome-text">
                <p>Welcome Back ,&nbsp; </p>{" "}
                <h2 className="winspire-username">
                  {"  "}
                  {profiledata?.message?.first_name}
                </h2>
                <span className="dropdown-arrow">
                  {" "}
                  <FaCaretDown />
                </span>
              </span>
            </div>
            {showMenu && (
              <div className="user-menu">
                <button className="logout-btn" onClick={(e) => handleLogOut(e)}>
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <span className="welcome-text">Welcome, Guest</span>
        )}
      </div>

      {/* Center Section - Logo */}
      <div className="winspire-header-center">
        {main_header
          .filter((item) => item.type_of_the_menu === "customer_logo")
          .map((logoItem) => (
            <a
              key={logoItem.id}
              href={logoItem.route_name || "#"}
              className="logo-link"
            >
              <img
                src={`${BASE_URL}${logoItem.name_of_the_menu}`}
                alt="Customer Logo"
                className="winspire-logo"
              />
            </a>
          ))}
      </div>

      {/* Right Section - Icons */}
      <div className="winspire-header-right">
        {main_header
          .filter((item) => item.type_of_the_menu === "customer_menu_icon")
          .map((iconItem) => (
            <a
              key={iconItem.id}
              href={iconItem.route_name ? iconItem.route_name : "#"}
              className="header-icon-link"
            >
              <img
                src={`${BASE_URL}${iconItem.icon}`}
                alt={iconItem.name_of_the_menu}
                className="header-icon"
                style={
                  iconItem.name_of_the_menu === "Settings"
                    ? { filter: "brightness(0) invert(1)" }
                    : iconItem.name_of_the_menu === "Mail" &&
                      parseInt(menuEnable?.is_mail) === 0
                    ? { display: "none" }
                    : iconItem.name_of_the_menu === "Notification" &&
                      parseInt(menuEnable?.is_notification) === 0
                    ? { display: "none" }
                    : {}
                }
              />
            </a>
          ))}
      </div>
    </div>
  );
};

export default WinspireHeader;
