
import React, { useState, useEffect } from 'react';
import { useGetCandidateInterviewsQuery } from '../../../../Redux/API/atsSlice';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";
import "./CandidateInterview.css";
import { BsThreeDots } from "react-icons/bs";
import EmptyData from '../../../EmptyData/EmptyData';

const CandidateInterview = () => {
  const [params] = useSearchParams();
  const isAuth = useSelector((state) => state.auth);
  const { data } = useGetCandidateInterviewsQuery({
    domain_name: isAuth?.user?.domain_name,
    id: params.get('id') || 'null',
  });

  const [interviews, setInterviews] = useState([]);
  const [selectedJob, setSelectedJob] = useState('');
  const [filteredInterviews, setFilteredInterviews] = useState([]);

  useEffect(() => {
    if (data?.message?.interviews) {
      setInterviews(data.message.interviews);
      const uniqueJobs = [...new Set(data.message.interviews.map(i => i.interview_for))];
      if (uniqueJobs.length > 0) setSelectedJob(uniqueJobs[0]);
    }
  }, [data]);

  // useEffect(() => {
  //   setFilteredInterviews(interviews.filter(interview => interview.interview_for === selectedJob));
  // }, [selectedJob, interviews]);
  useEffect(() => {
    const now = new Date();
    const upcoming = interviews.filter(interview => interview.interview_for === selectedJob && interview.interview_date && new Date(interview.interview_date) > now);
    const completed = interviews.filter(interview => interview.interview_for === selectedJob && (!interview.interview_date || new Date(interview.interview_date) <= now));
    setFilteredInterviews([...upcoming, ...completed]);
  }, [selectedJob, interviews]);


  const formatDate = (dateString) => dateString ? new Date(dateString).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: '2-digit' }) : 'Not Available';

  const getInterviewerLabel = (interviewerData) => {
    try {
      const parsed = JSON.parse(interviewerData);
      return parsed?.map((i) => i.label).join(', ') || 'Not Assigned';
    } catch {
      return 'Not Assigned';
    }
  };


  return (
    <div>
      {interviews.length>0 ? (<>
      
        <label className='candidate-interview-label'>Select Job: </label>
         <select className='candidate-interview-select' value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)}>
        {[...new Set(interviews.map(i => i.interview_for))].map((job, index) => (
          <option key={index} value={job}>{job}</option>
        ))}
      </select>
      <span className='candidate-interview-line'></span>
      <label className='candidate-interview-label'>Current Stage: </label>
      <span className='candidate-interview-toptext'>{filteredInterviews.find((item) => item?.interview_stage)?.interview_stage || "Not Applicable"}</span>

      {filteredInterviews.map((interview, index) => (
        <div key={index} style={{ borderBottom: '1px solid #ddd', padding: '10px 0' }} className="candidate-interview-container">
          <span
            style={{
              display: 'inline-block',

              padding: '3px 10px',
              borderRadius: '10px',
              backgroundColor: interview.interview_date && new Date(interview.interview_date) > new Date() ? '#d4f8d4' : '#f8d4d4',
              color: interview.interview_date && new Date(interview.interview_date) > new Date() ? 'green' : 'red',
              fontWeight: 400,
              fontSize: '10px'
            }}
          >
            {interview.interview_date && new Date(interview.interview_date) > new Date() ? 'Upcoming' : 'Completed'}
          </span>

          <div style={{ display: 'flex', textAlign: 'center' }}> <p className='candidate-interview-heading'>{isAuth?.user?.firstname} scheduled a meeting <span><BsThreeDots /></span> </p> </div>
          <p className='candidate-interview-text'>{interview.interview_type} - {interview.interview_for} - {interview?.interview_stage}</p>
          <p className='candidate-interview-text'>Interviewer: {getInterviewerLabel(interview.interviewer)}</p>
          <p className='candidate-interview-text'>When: {formatDate(interview.interview_date)} at {interview.start_time || 'Not Scheduled'} ({interview.timezone || 'Not Specified'})</p>
          <p className='candidate-interview-text'>Where: <a href={interview.link} target="_blank" rel="noopener noreferrer">Join here</a></p>
          <p className='candidate-interview-text'><a href="#">Interview Kit</a></p>

        </div>
      ))}

      </>):(<EmptyData/>)}

          </div>
  );
};

export default CandidateInterview;
