const FormValidator = (customInputs) => {
    try {
        let hasError = false;

        if (Array.isArray(customInputs)) {
            const updatedInputs = customInputs.map((item) => {
                const updatedFields = item.fields.map((ele) => {
                    const hasFieldError = !ele?.valueOftheField;
                    if (hasFieldError) hasError = true;
                    return { ...ele, error: hasFieldError };
                });

                return { ...item, fields: updatedFields };
            });
            console.log("Updated",hasError)
            return {
                isError: hasError,
                Formdata: updatedInputs
            };
        } else {
            return {
                isError: true,
                data: []
            };
        }

    } catch (err) {
        console.error("Validation error:", err);
        return {
            isError: true,
            data: []
        };
    }
};

export default FormValidator;
